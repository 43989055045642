<template>
  <el-dialog
    :title="isEdit === true ? '编辑' : '新增'"
    :visible.sync="show"
    width="400px"
    center
  >
    <el-form
      v-if="show"
      :model="form"
      :label-width="formLabelWidth"
      label-position="left"
      ref="form"
      :rules="rules"
      v-loading="loading"
    >
      <el-form-item label="标签分类" style="width: 100%">
        <el-select v-model="form.cat_id" prop="account_type">
          <el-option
            v-for="item in cateData"
            :key="item.id"
            :label="item.cat_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签名" prop="tag_name">
        <el-input v-model="form.tag_name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="代表价值" prop="weight">
        <el-input v-model.number="form.weight" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="排序值" prop="sort_order">
        <el-input
          v-model.number="form.sort_order"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import API from "@/api";

export default {
  name: "addForm",
  data() {
    return {
      formLabelWidth: "100px",
      form: {
        cat_id: "",
        tag_name: "",
        weight: "",
        sort_order: "",
      },
      isEdit: false,
      show: false,
      cateData: [],
      rules:{
        cat_id: [
          { required: true, message: "请选择标签分类", trigger: "change" },
        ],
        tag_name: [
          { required: true, message: "请输入标签名", trigger: "blur" },
        ],
        weight: [
          { required: true, message: "请输入代表价值", trigger: "blur" },
        ],
        sort_order: [
          { required: true, message: "请输入排序值", trigger: "blur" },
        ],
      },
      loading:false
    };
  },
  methods: {
    submit() {
      const funName = this.isEdit ? "updateData" : "addData";
      this.$refs.form.validate((valid) => {
        valid ? this[funName]() : "";
      });
    },
    async updateData() {
      const { data,msg } = await API.tagUpdate(this.form)
        .then((res) => {
          this.$message.success("编辑成功");
          this.show = false;
          this.$parent.getCateList();
          console.log(res, data);
        })
        .catch((err) => {
          this.$message.error(msg);
          console.log(err);
        });
    },
    editData(data) {
      this.getList();
      this.form = data;
      this.isEdit = true;
      this.show = true;
    },
    clearData() {
      this.form = {
        cat_id: "",
        tag_name: "",
        weight: "",
        sort_order: "",
      };
    },
    addFormData() {
      this.isEdit = false;
      this.clearData();
      this.getList();
      this.show = true;
    },
    async addData() {
      const { data ,msg} = await API.createTag(this.form)
        .then((res) => {
          this.$message.success("创建成功");
          this.show = false;
          this.$parent.getCateList();
          console.log(res, data);
        })
        .catch((err) => {
          this.$message.error(msg);
          console.log(err);
        });
    },
    getList() {
      this.loading = true
      let params = {
        page: 1,
        size: 1000,
      };
      API.getCateList(params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.cateData = res.data.data;
            console.log("this.cateData ", this.cateData);
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            return;
          } else {
            this.cateData = [];
          }
          this.loading = false;
        })
        .catch(() => {
          this.tableData = [];
          this.loading = false;
        });
    },
    created() {
      this.getList();
    },
  },
};
</script>

<style scoped>
</style>
