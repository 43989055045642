<template>
  <div
    class="videoList-box"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!--操作栏-->
    <div class="action-bar">
      <el-form :model="params" label-width="80" inline>
        <el-form-item label="标签分类">
          <el-select filterable :clearable="true" v-model="params.cat_id">
            <el-option
              :key="item.id"
              v-for="item in cateData"
              :label="item.cat_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签名">
          <el-input placeholder="标签名" v-model="params.tag_name"></el-input>
        </el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button
          type="success"
          @click="addFromMethod"
          v-has="'/api/tag/create'"
          >添加</el-button
        >
      </el-form>
      <refreshbtn @refesh="refesh" :size="'normal'"></refreshbtn>
    </div>
    <!--数据表格-->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      ref="multipleTable"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="580"
      :key="updataSta"
    >
      <el-table-column
        prop="id"
        label="Id"
        align="center"
        width="200"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="cat_id"
        label="标签分类"
        align="center"
        :formatter="changeData"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="tag_name" label="标签名" align="center">
      </el-table-column>
      <el-table-column
        label="代表价值"
        align="center"
        width="120"
        prop="weight"
      >
      </el-table-column>
      <el-table-column
        label="排序值"
        prop="sort_order"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            type="text"
            v-has="'/api/tag/update'"
            size="small"
            @click="editForm(scope.row)"
          >
            编辑
          </el-button>
          <el-button
            type="text"
            v-has="'/api/tag/destroy'"
            size="small"
            @click="destroyTag(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--封面图、视频弹窗组件-->
    <!-- 分页组件 -->
    <div class="bottom">
      <div class="page">
        <el-pagination
          :page-sizes="[10, 25, 50, 100]"
          :current-page="params.page"
          :page-size="params.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          v-if="total > 10"
          @size-change="changeSize"
          @current-change="changeCurrent"
        ></el-pagination>
      </div>
    </div>
    <addTag ref="addForm"></addTag>
  </div>
</template>

<script>
import addTag from "./components/addTag.vue";
import API from "@/api";

export default {
  data() {
    return {
      // 请求列表查询
      params: {
        page: 1,
        size: 10,
        cat_id: "",
        tag_name: "",
        min: undefined,
        max: undefined,
      },
      updataSta: false,
      loading: false,
      // 列表数据
      tableData: [],
      cateData: [],
      total: "",
      dialogVisible: false,
      isRefesh: true,
    };
  },
  components: {
    addTag,
  },
  created() {
    let listRule = "/api/tag/list";
    let obj = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem("btnPower")))
    );
    console.log(obj[listRule]);
    if (obj[listRule]) {
      this.isRefesh = true;
      this.getCateList();
    } else {
      this.isRefesh = false;
    }
  },
  methods: {
    refesh() {
      this.isRefesh ? this.getCateList() : "";
    },
    search() {
      this.params.page = 1;
      this.getCateList();
    },
    destroyTag(id) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API.destroyTag({ id }).then((res) => {
            if (!res.code) {
              this.$message.success("删除成功");
              this.getCateList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message.error("已取消删除");
        });
    },
    addFromMethod() {
      this.$refs.addForm.addFormData();
    },
    editForm(data) {
      this.$refs.addForm.editData(this._.cloneDeep(data));
    },
    getList() {
      API.getTagList(this.params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.tableData = res.data.data;
            this.total = res.data.pagination.count;
          } else if (res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            return;
          } else {
            this.tableData = [];
            this.total = 0;
          }
          this.loading = false;
        })
        .catch(() => {
          this.tableData = [];
          this.loading = false;
        });
    },
    getCateList() {
      this.loading = true;
      API.getCateList({ page: 1, size: 9999 }).then((res) => {
        if (res.code === 0 && res.data.data) {
          this.cateData = res.data.data;
        }
        this.getList();
      });
    },
    // 分页条数改变回调
    changeSize(size) {
      this.params.size = size;
      this.getCateList();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.params.page = number;
      this.getCateList();
    },
    changeData(row) {
      const newArr = this.cateData.filter((item) => item.id == row.cat_id);
      return newArr.length ? newArr[0].cat_name : "";
    },
  },
};
</script>
<style scoped lang="scss">
.picturePreview {
  max-width: 300px;
  display: flex;
}

.videoList-box {
  min-height: 100vh;
  overflow-y: auto;
}

.bottom {
  margin-top: 20px;

  .btn {
    display: inline-block;
    margin-left: 20px;
  }

  .page {
    display: inline-block;
    float: right;
  }
}

.el-pagination {
  margin-top: 0 !important;
}

.dialog-footer {
  left: 0;
}

.tagBox {
  display: inline-block;
  margin-right: 5px;
}
.action-bar {
  display: flex;
}
</style>
